<template>
    <div class="row common-error-404-outer">
        <div class="col-12">
            <div class="d-flex justify-content-center align-items-center common-error-404-inner">
                <div v-if="!verificationloader">
                    <div class="common-error-404-box">
                        <div class="error-404-baner">
                            <div class="text-center">
                                <img src="/assets/images/email-verify.png" class="img-fluid" alt="404"
                                    style="height: 120px;" />
                            </div>
                        </div>
                        <div class="heading">Success!</div>
                        <div class="subheading">
                            <div class="mb-2 text-capitalize">Dear {{this.username}},</div>
                            <div class="mb-4">Your Email address verification has been completed Successfully.</div>
                            <div>Thank you!</div>
                        </div>
                    </div>
                </div>
                <div v-if="verificationloader">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner text-white" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../service/ApiService";
export default {
    data() {
        return {
            verificationloader: false,
            routeParam: '',
            username: '',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.verificationloader = true;
        if(this.$route.query.value) {
            this.routeParam = this.$route.query.value;
        }
        this.verifyEmail({ak1: this.routeParam });
    },
    methods: {
        verifyEmail(e) {
            this.ApiService.verifyEmail(e).then((data) => {
                if (data.success === true) {
                    this.verificationloader = false;
                    this.username = data.data.ak5;
                } else {
                    this.verificationloader = false;
                    this.username = '';
                }
            });
        },
    }
};
</script>
<style scoped>
.common-error-404-outer {
    background: #00175C;
    opacity: 1;
    padding: 0;
}

.common-error-404-outer .common-error-404-inner {
    height: 100vh;
}

.common-error-404-box .error-404-baner {
    padding-bottom: 47px;
    text-align: center;
    padding-top: 47px;
}

.common-error-404-box .heading {
    font-family: 'AcuminPro-SemiBold';
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    line-height: 33px;
    text-align: center;
    padding-bottom: 30px;
}

.common-error-404-box .subheading {
    font-family: 'AcuminPro-Regular';
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    line-height: 25px;
    margin-bottom: 20px;
}
</style>